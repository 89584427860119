<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="30px"
    height="30px"
    class="ms-4"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <rect x="64" y="64" style="fill:#ECEFF1;" width="384" height="384" />
    <polygon style="fill:#CFD8DC;" points="256,296.384 448,448 448,148.672 " />
    <path
      style="fill:#F44336;"
      d="M464,64h-16L256,215.616L64,64H48C21.504,64,0,85.504,0,112v288c0,26.496,21.504,48,48,48h16V148.672
	l192,147.68L448,148.64V448h16c26.496,0,48-21.504,48-48V112C512,85.504,490.496,64,464,64z"
    />
  </svg>
</template>

<script>
export default {
  name: "EmailSvg"
};
</script>

<style scoped></style>
