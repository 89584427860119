<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    class="ms-4"
  >
    <path
      d="m256 0c-140.699219 0-256 115.300781-256 256 0 46.5 12.597656 91.5 36.300781 131.101562l-36.300781 124.898438 124.898438-36.300781c39.601562 23.699219 84.601562 36.300781 131.101562 36.300781 140.699219 0 256-115.300781 256-256s-115.300781-256-256-256zm0 0"
      fill="#00dd7b"
    />
    <path
      d="m512 256c0 140.699219-115.300781 256-256 256v-512c140.699219 0 256 115.300781 256 256zm0 0"
      fill="#00cc71"
    />
    <path
      d="m417.199219 364.300781-12 11.699219c-16.800781 16.800781-55.800781 15.597656-80.699219 10.800781-22.199219-4.199219-46-14.101562-68.5-27.902343-61.199219-37.199219-116.699219-103.199219-130.199219-162.597657-9.300781-40.203125-4.199219-75 9-88.5l12-12c6.601563-6.300781 17.097657-6.300781 23.699219 0l47.699219 47.699219c3.300781 3.300781 5.101562 7.5 5.101562 12s-1.800781 8.699219-5.101562 12l-12 11.699219c-12.898438 13.199219-12.898438 34.5 0 47.699219l49.800781 48.902343 29.097656 28.800781c13.203125 13.199219 35.503906 13.199219 48.703125 0l11.699219-12.003906c6.300781-6 17.699219-6 24 0l47.699219 47.703125c6.300781 6.597657 6.601562 17.097657 0 24zm0 0"
      fill="#ececf1"
    />
    <path
      d="m417.199219 364.300781-12 11.699219c-16.800781 16.800781-55.800781 15.597656-80.699219 10.800781-22.199219-4.199219-46-14.101562-68.5-27.902343v-83.097657l29.097656 28.800781c13.203125 13.199219 35.503906 13.199219 48.703125 0l11.699219-12.003906c6.300781-6 17.699219-6 24 0l47.699219 47.703125c6.300781 6.597657 6.601562 17.097657 0 24zm0 0"
      fill="#e2e2e7"
    />
  </svg>
</template>

<script>
export default {
  name: "WhatsappLogo"
};
</script>

<style scoped></style>
