<template>
  <v-btn
    @click="savexlsx"
    class="text-center"
    large
    :loading="loading"
    :disabled="items.length <= 0 || loading"
    >{{ $t("save") }}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      width="30px"
      height="30px"
      class="ms-4"
    >
      <path
        fill="#fff"
        d="M74.5,11.5H42v57h32.5c1.105,0,2-0.895,2-2v-53C76.5,12.395,75.605,11.5,74.5,11.5z"
      />
      <path
        :fill="items.length <= 0 || loading ? '#a6a6a6' : '#4caf50'"
        d="M46.5 76L3.5 68 3.5 12 46.5 4z"
      />
      <path
        fill="#fff"
        d="M30.26,54l-4.82-9.12c-0.272-0.599-0.464-1.231-0.57-1.88h-0.08c-0.163,0.682-0.377,1.35-0.64,2 l-4.85,9h-7.51l8.92-14l-8.16-14h7.67l4,8.39c0.355,0.758,0.636,1.548,0.84,2.36h0.08c0.16-0.54,0.45-1.36,0.88-2.44L30.47,26h7 l-8.36,13.88L37.74,54H30.26z"
      />
      <path
        fill="#e0e9f0"
        d="M71 29H56v-7h15V29zM57 28h13v-5H57V28zM53 29L46.75 29 46.75 28 52 28 52 23 46.75 23 46.75 22 53 22zM71 39H56v-7h15V39zM57 38h13v-5H57V38zM71 49H56v-7h15V49zM57 48h13v-5H57V48zM53 39L46.75 39 46.75 38 52 38 52 33 46.75 33 46.75 32 53 32zM53 49L46.75 49 46.75 48 52 48 52 43 46.75 43 46.75 42 53 42zM71 59H56v-7h15V59zM57 58h13v-5H57V58zM53 59L46.75 59 46.75 58 52 58 52 53 46.75 53 46.75 52 53 52z"
      />
      <path
        fill="#5d9874"
        d="M37.75 55h-7.48c-.189 0-.362-.106-.447-.275l-4.83-9.62c-.003-.006-.006-.012-.008-.018-.062-.137-.12-.274-.174-.414-.062.173-.128.345-.197.515l-4.787 9.537C19.742 54.894 19.569 55 19.38 55h-7.52c-.181 0-.348-.098-.436-.255-.088-.158-.085-.352.009-.506l8.697-14.25-8.016-14.245c-.087-.155-.086-.344.004-.498S12.372 25 12.55 25h7.67c.197 0 .375.115.456.295l4 8.89c.163.348.311.705.445 1.068.128-.347.273-.725.435-1.128l4.468-8.851C30.109 25.106 30.282 25 30.47 25h7c.179 0 .344.096.434.25.089.155.088.346-.001.501l-8.213 14.126 8.491 14.368c.091.154.093.346.003.502C38.095 54.903 37.93 55 37.75 55zM30.579 54h6.295l-8.194-13.866c-.092-.155-.093-.349-.001-.505L36.601 26h-5.823l-4.312 8.536c-.483 1.218-.729 1.958-.847 2.356-.063.212-.258.358-.479.358-.229 0-.509-.156-.565-.378-.196-.779-.468-1.543-.808-2.27L19.897 26h-6.492l7.741 13.755c.089.158.085.352-.009.506L12.751 54h6.32l4.632-9.225c.236-.586.443-1.235.601-1.892.054-.226.255-.384.486-.384.245 0 .534.178.574.419.099.604.276 1.19.527 1.745L30.579 54zM74.5 69h-28v-1h28c.827 0 1.5-.673 1.5-1.5v-53c0-.827-.673-1.5-1.5-1.5H46v-1h28.5c1.379 0 2.5 1.122 2.5 2.5v53C77 67.879 75.879 69 74.5 69z"
      />
      <path
        fill="#5d9874"
        d="M47,76.602L3,68.416V11.584l44-8.186V76.602z M4,67.584l42,7.814V4.602L4,12.416V67.584z"
      />
    </svg>
  </v-btn>
</template>

<script>
import XLSX from "xlsx";

function set_right_to_left(wb) {
  if (!wb.Workbook) wb.Workbook = {};
  if (!wb.Workbook.Views) wb.Workbook.Views = [];
  if (!wb.Workbook.Views[0]) wb.Workbook.Views[0] = {};
  wb.Workbook.Views[0].RTL = true;
}
function ChangeKey(o, old_key, new_key) {
  if (old_key !== new_key) {
    Object.defineProperty(
      o,
      new_key,
      Object.getOwnPropertyDescriptor(o, old_key)
    );
    delete o[old_key];
  }
  return o;
}
export default {
  name: "SaveXls",
  props: {
    items: { type: Array, required: true },
    headers: { type: Array, required: true },
    fileName: { type: String, required: true }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    savexlsx() {
      this.loading = true;

      let headersArr = JSON.parse(JSON.stringify(this.headers));
      let itemsArr = JSON.parse(JSON.stringify(this.items));

      /*      if (itemsArr[0].subscriber != null) {
        itemsArr.map(function(item) {
          item.subscriber = item.subscriber.name;
          return item;
        });
      }*/

      let headVal = headersArr.map(function(item) {
        return item["value"];
      });
      let headText = headersArr.map(function(item) {
        return item["text"];
      });

      let deleted = Object.keys(itemsArr[0]).diff(headVal);

      itemsArr.map(function(item) {
        deleted.forEach(function(deleteItem) {
          delete item[deleteItem];
        });
        headersArr.forEach(function(he) {
          Object.keys(item).forEach(function(key) {
            if (he.value === key) ChangeKey(item, key, he.text);
          });
        });
        return item;
      });
      let ws = XLSX.utils.json_to_sheet(itemsArr, { header: headText });
      let workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, ws, this.fileName);

      if (this.$root.$i18n.locale === "ar") set_right_to_left(workbook);

      XLSX.writeFile(workbook, this.fileName + ".xls");
      this.loading = false;
    }
  }
};
</script>
