<template>
  <v-dialog v-model="$store.state.loginDialog" max-width="500">
    <v-card
      color="white"
      class="rounded-lg"
      flat
      @keypress.enter.prevent="login()"
    >
      <v-card-title class="no_select pt-5 pe-2">
        {{ $t("please_login") }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form_sign_in"
          lazy-validation
          class="mt-8"
          @submit="login()"
        >
          <v-text-field
            :value="initData.form.Login1$UserName"
            @input="$emit('input', $event.target.initData.form.Login1$UserName)"
            :label="$t('username')"
            :rules="rules"
            required
            name="username"
            type="username"
            class="rounded-lg"
            height="60"
            solo
            disabled
          ></v-text-field>

          <v-text-field
            :value="initData.form.Login1$Password"
            @input="$emit('input', $event.target.initData.form.Login1$Password)"
            :label="$t('password')"
            name="password"
            :rules="rules"
            class="rounded-lg"
            height="60"
            solo
            rounded
            required
            :append-icon="showPass ? 'visibility' : 'visibility_off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
          ></v-text-field>

          <v-text-field
            :value="initData.form.Login1$txt2FaCode"
            @input="
              $emit('input', $event.target.initData.form.Login1$txt2FaCode)
            "
            :label="$t('code2f')"
            name="code2f"
            type="code2f"
            :rules="rules"
            class="rounded-lg"
            height="60"
            solo
            rounded
            required
            @keypress="numberOnly($event)"
          ></v-text-field>

          <v-row>
            <v-col cols="6">
              <v-card
                color="rgb(214,209,189)"
                :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
              >
                <v-img height="70" contain :src="initData.image"></v-img>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="initData.form.Login1$ImageVerificationDealer$txtContent"
                @input="
                  $emit(
                    'input',
                    $event.target.initData.form
                      .Login1$ImageVerificationDealer$txtContent
                  )
                "
                :label="$t('image_code')"
                name="imageCode"
                type="imageCode"
                :rules="rules"
                solo
                required
                height="70"
                :class="$vuetify.rtl ? 'rounded-l-lg' : 'rounded-r-lg'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end">
        <div class="mx-auto">
          <v-alert
            :value="alert"
            dense
            color="red darken-2"
            type="error"
            dismissible
            tile
            transition="slide-y-reverse-transition"
          >
            {{ $t(message) }}
          </v-alert>
        </div>
        <v-btn
          color="#321059"
          class="font-weight-bold white--text ma-2"
          x-large
          min-width="170"
          elevation="0"
          @click="login()"
          :loading="loading"
          >{{ $t("sign_in") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sign_in } from "@/api/URLs";

export default {
  name: "LoginDialog",
  data() {
    return {
      showPass: false,
      loading: false,
      alert: false,
      message: "",
      emailRules: [
        (v) => !!v || this.$t("email_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("email_valid"),
      ],
      rules: [(v) => !!v || this.$t("this_required")],
    };
  },
  props: {
    initData: {
      type: Object,
      default: function () {
        return { form: {} };
      },
    },
  },
  methods: {
    numberOnly($event) {
      if ($event.keyCode < 48 || $event.keyCode > 57) $event.preventDefault();
    },
    removeSpace($event) {
      if ($event.keyCode === 32) $event.preventDefault();
    },
    login() {
      this.alert = false;
      if (this.$refs.form_sign_in.validate()) {
        this.loading = true;
        let self = this;
        this.$http({
          method: "post",
          url: sign_in,
          data: self.initData,
        }).then(function (response) {
          console.log(response);

          if (response.data.success) {
            self.$store.state.loginDialog = false;
          } else {
            if (response.data.data != null) {
              response.data.data.form.Login1$Password =
                self.initData.form.Login1$Password;
              self.initData = response.data.data;
              self.$refs.form_sign_in.resetValidation();
            }
            self.message = response.data.errors[0];
            self.alert = true;
            self.loading = false;
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
