<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
    width="30px"
    height="30px"
    class="ms-4"
  >
    <path
      style="fill:#87E694;"
      d="M407,512H105C47.103,512,0,464.897,0,407V105C0,47.103,47.103,0,105,0h302
	c57.897,0,105,47.103,105,105v302C512,464.897,464.897,512,407,512z"
    />
    <path
      style="fill:#66CC70;"
      d="M407,0H256v512h151c57.897,0,105-47.103,105-105V105C512,47.103,464.897,0,407,0z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M256,106c-93.888,0-170,62.68-170,140c0,44.675,25.41,84.461,64.976,110.095L142,410l60.667-31.037
	C219.442,383.524,237.369,386,256,386c93.888,0,170-62.68,170-140S349.888,106,256,106z"
    />
    <path
      style="fill:#F2F2F2;"
      d="M256,106v280c93.888,0,170-62.68,170-140S349.888,106,256,106z"
    />
  </svg>
</template>

<script>
export default {
  name: "MessageSvg"
};
</script>

<style scoped></style>
