<template>
  <v-container class="dashboard">
    <v-row>
      <v-col cols="6">
        <v-card elevation="5">
          <v-card-title class="primary--text font-weight-black">
            {{ $t("account_information") }}
            <v-chip small class="ms-4" v-if="initData.updated_at">
              {{ $t("updated") }} {{ initData.updated_at | moment }}
            </v-chip>
          </v-card-title>
          <v-card-text class="font-weight-bold">
            <p>
              {{ $t("the_name") }}
              <span class="primary--text">{{ initData.name }}</span>
            </p>
            <!--            <p>
              {{ $t("total_credit_received") }}
              <span class="primary&#45;&#45;text">{{
                initData.total_credit_received | toCurrency
              }}</span>
            </p>-->
            <p class="ma-0">
              {{ $t("current_balance") }}
              <span class="primary--text">{{
                initData.current_balance | toCurrency
              }}</span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="5">
          <v-card-title class="primary--text font-weight-black">
            {{ $t("system_information") }}
            <v-chip small class="ms-4" v-if="initData.last_update">
              {{ $t("updated") }} {{ initData.last_update | moment }}
            </v-chip>
          </v-card-title>
          <v-card-text class="font-weight-bold">
            <p>
              {{ $t("invoices_complete") }}
              <span class="primary--text">{{
                initData.complete_invoices
              }}</span>
            </p>
            <p>
              {{ $t("subscribers_num") }}
              <span class="primary--text">{{ initData.subscribers }}</span>
            </p>
            <p class="ma-0">
              {{ $t("invoices_incomplete") }}
              <span
                :class="
                  initData.incomplete_invoices === 0
                    ? 'primary--text'
                    : 'error--text'
                "
                >{{ initData.incomplete_invoices }}
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--      <v-progress-linear
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-linear>
      <v-col cols="12" v-else>
        <v-alert outlined type="warning" v-if="initData.jobs !== 0">
          <v-row align="center" no-gutters>
            <v-col class="grow" cols="9">
              {{ $t("please_wait") }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink">
              <v-btn
                color="warning"
                outlined
                :loading="loadingRefresh"
                @click="incompleteCount()"
                >{{ $t("refresh") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert
          outlined
          type="error"
          v-else-if="initData.incomplete_invoices !== 0"
        >
          <v-row align="center" no-gutters>
            <v-col class="grow" cols="9">
              {{ $t("reload_alert", { count: initData.incomplete_invoices }) }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink">
              <v-btn
                color="error"
                outlined
                :loading="loading"
                @click="complete()"
                >{{ $t("retry") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <v-alert v-else outlined color="primary">
          <v-row align="center" no-gutters>
            <v-col class="grow" cols="9">
              {{
                initData.last_update ? $t("update_alert") : $t("start_alert")
              }}
              {{ initData.last_update | moment }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink">
              <v-btn
                color="primary"
                outlined
                :loading="loading"
                @click="refresh()"
                >{{ initData.last_update ? $t("update_now") : $t("start") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>-->
      <v-col cols="12" class="text-center">
        <span>{{ $t("pages") }} :</span>
        <v-btn
          to="/messages"
          class="ma-4"
          large
          outlined
          color="rgb(102, 204, 112)"
          >{{ $t("messages") }}
          <message-svg />
        </v-btn>
        <v-btn
          to="/emails"
          class="ma-4"
          large
          outlined
          color="rgb(244, 67, 54)"
        >
          {{ $t("emails") }}<email-svg />
        </v-btn>
        <v-btn to="/pos" class="ma-4" large outlined color="primary">
          {{ $t("pos") }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="$store.state.selected"
          :headers="headers"
          :items="invoices.data"
          class="elevation-5 custom-table"
          item-key="invoice_no"
          show-select
          :loading="loadingTable"
          :loading-text="$t('loading')"
          :no-data-text="$t('no_data')"
          :no-results-text="$t('not_found')"
          :items-per-page="20"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" height="none">
              <v-container fluid class="ms-4">
                <v-text-field
                  v-model="filterVal.search"
                  append-icon="search"
                  :label="$t('search')"
                  single-line
                  hide-details
                  filled
                  dense
                  rounded
                  class="mx-auto"
                  @click:append="filters()"
                  @keypress.enter.prevent="filters()"
                  style="max-width: 500px"
                  clearable
                  @click:clear="filters('clear_search')"
                ></v-text-field>
                <v-expansion-panels multiple class="mt-5">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3 class="primary--text">{{ $t("filters") }}</h3>
                      <span
                        class="mx-9 grey--text text--darken-2"
                        v-text="$t('filters_ext')"
                      />
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                            v-model="filterVal.select.country"
                            :items="initData.countries"
                            :label="$t('countries')"
                            item-text="name"
                            hide-details
                            chips
                            clearable
                            deletable-chips
                            multiple
                            solo
                            :no-data-text="$t('no_data')"
                            @change="filters()"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="6">
                          <v-autocomplete
                            v-model="filterVal.select.item_type"
                            :items="initData.item_types"
                            :label="$t('item_types')"
                            item-text="name"
                            hide-details
                            chips
                            clearable
                            deletable-chips
                            multiple
                            solo
                            :no-data-text="$t('no_data')"
                            @change="filters()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            v-model="filterVal.select.package_plan"
                            :items="initData.package_plans"
                            :label="$t('package_plans')"
                            item-text="name"
                            hide-details
                            chips
                            clearable
                            deletable-chips
                            multiple
                            solo
                            :no-data-text="$t('no_data')"
                            @change="filters()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            v-model="filterVal.select.package_name"
                            :items="initData.package_names"
                            :label="$t('package_names')"
                            item-text="name"
                            hide-details
                            chips
                            clearable
                            deletable-chips
                            multiple
                            solo
                            :no-data-text="$t('no_data')"
                            @change="filters()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="text-center">
                          <date-range-picker
                            ref="picker"
                            opens="center"
                            :autoApply="true"
                            v-model="dateRange"
                            :linkedCalendars="false"
                            @update="filters()"
                            class="mt-2 elevation-2"
                          >
                            <template
                              v-slot:input="picker"
                              style="min-width: 350px"
                            >
                              {{ picker.startDate | date }}
                              {{ picker.startDate ? "-->" : $t("select_date") }}
                              {{ picker.endDate | date }}
                            </template>
                          </date-range-picker>
                          <v-btn
                            v-if="dateRange.startDate"
                            icon
                            small
                            @click="filters('clear_date')"
                            class="ms-2"
                          >
                            <v-icon>clear</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            v-model="filterVal.type_date"
                            :items="dateList"
                            :label="$t('date_options')"
                            solo
                            single-line
                            clearable
                            @change="filters()"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="mx-auto">
                          <v-autocomplete
                            v-model="filterVal.select.point_sales"
                            :items="initData.pos"
                            :label="$t('pos')"
                            item-text="name"
                            hide-details
                            chips
                            clearable
                            deletable-chips
                            multiple
                            solo
                            :no-data-text="$t('no_data')"
                            @change="filters()"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3 class="primary--text">{{ $t("actions") }}</h3>
                      <span
                        class="mx-9 grey--text text--darken-2"
                        v-text="$t('actions_ext')"
                      />
                      <v-chip
                        class="flex-none mx-2"
                        :disabled="$store.state.selected.length <= 0"
                        v-text="$store.state.selected.length"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12" class="text-center">
                        <save-xls
                          :items="$store.state.selected"
                          :headers="headers"
                          file-name="Subscribers"
                          class="ma-4"
                        />
                        <v-btn
                          :disabled="$store.state.selected.length <= 0"
                          class="ma-4"
                          large
                          to="/send/messages"
                          >{{ $t("send_sms") }}
                          <message-svg />
                        </v-btn>
                        <v-btn
                          :disabled="$store.state.selected.length <= 0"
                          class="ma-4"
                          large
                          @click="$store.commit('showToast', 'not_available')"
                        >
                          {{ $t("send_whatsapp") }}<whatsapp-logo />
                        </v-btn>
                        <v-btn
                          :disabled="$store.state.selected.length <= 0"
                          class="ma-4"
                          large
                          to="/send/emails"
                        >
                          {{ $t("send_email") }}<email-svg />
                        </v-btn>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3 class="primary--text">{{ $t("table_columns") }}</h3>
                      <span
                        class="mx-9 grey--text text--darken-2"
                        v-text="$t('table_col_ext')"
                      />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <v-chip-group
                          v-model="headers"
                          active-class="primary--text"
                          column
                          mandatory
                          multiple
                        >
                          <v-chip
                            v-for="header in headersAll"
                            :key="header.value"
                            :value="header"
                            :disabled="loadingTable"
                            small
                          >
                            {{ header.text }}
                          </v-chip>
                        </v-chip-group>
                        <div class="text-center mb-2">
                          <v-chip
                            small
                            @click="headers = [headersAll[0]]"
                            color="primary"
                            :disabled="loadingTable"
                          >
                            {{ $t("unselect_all") }}
                          </v-chip>
                        </div>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <div class="text-center mt-5">
                  <v-container>
                    <v-row justify="center">
                      <v-col cols="8">
                        <v-container class="max-width">
                          <v-pagination
                            v-model="invoices.meta.current_page"
                            :length="invoices.meta.last_page"
                            @input="filters('pagination')"
                            :disabled="loadingTable"
                          ></v-pagination>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <v-row>
                  <v-col>
                    <v-subheader class="mt-3">
                      {{ $t("about_results", { count: invoices.meta.total }) }}
                    </v-subheader>
                  </v-col>
                  <v-col>
                    <v-btn
                      class="mx-auto"
                      color="primary"
                      :disabled="$store.state.selected.length <= 0"
                      outlined
                      small
                      @click="$store.state.selected = []"
                      >{{ $t("unselect_all") }}
                      {{
                        $store.state.selected.length > 0
                          ? $store.state.selected.length
                          : ""
                      }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="perPage"
                      hide-details
                      dense
                      solo
                      :label="$t('count_per_page')"
                      type="number"
                      :loading="loadingTable"
                      style="max-width: 200px"
                    >
                      <template v-slot:append-outer>
                        <v-btn @click="changePage" :disabled="loadingTable">{{
                          $t("apply")
                        }}</v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
          </template>
          <template v-slot:[`item.package_amount`]="{ item }">
            {{ item.package_amount | toCurrency }}
          </template>
          <template v-slot:[`item.total_amount`]="{ item }">
            {{ item.total_amount | toCurrency }}
          </template>

          <template v-slot:footer>
            <div class="text-center">
              <v-container>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-container class="max-width">
                      <v-pagination
                        v-model="invoices.meta.current_page"
                        :length="invoices.meta.last_page"
                        @input="filters('pagination')"
                        :disabled="loadingTable"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <login-dialog :dialog="loginDialog" :init-data="loginData" />
  </v-container>
</template>

<script>
import { init, filter, refresh, complete, incompleteCount } from "@/api/URLs";
import LoginDialog from "@/components/LoginDialog";
import { getInvoicesH } from "@/api/headers";
import SaveXls from "@/components/widgets/SaveXls";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import MessageSvg from "@/components/widgets/MessageSvg";
import EmailSvg from "@/components/widgets/EmailSvg";
import WhatsappLogo from "@/components/widgets/WhatsappLogo";

export default {
  name: "Dashboard",
  components: {
    WhatsappLogo,
    EmailSvg,
    MessageSvg,
    SaveXls,
    LoginDialog,
    DateRangePicker,
  },

  watch: {
    "$root.$i18n.locale": function () {
      let headersPure = getInvoicesH();
      this.headers = headersPure;
      this.headersAll = headersPure;
    },
  },
  data: () => ({
    loading: false,
    loadingRefresh: false,
    loadingTable: false,
    loginDialog: false,
    loginData: { form: {} },
    initData: {},
    invoices: { data: [], links: {}, meta: {} },
    headers: [],
    headersAll: [],
    dateList: [],
    dateRange: { startDate: null, endDate: null },
    filterVal: { select: { country: [] } },
    lastPage: 0,
    perPage: 50,
  }),
  created() {
    this.dateList = [
      { text: this.$t("contract_start"), value: "contract_start_date" },
      { text: this.$t("contract_expiry"), value: "contract_end_date" },
      { text: this.$t("next_payment"), value: "next_payment" },
    ];
    let headersPure = getInvoicesH();
    this.headers = headersPure;
    this.headersAll = headersPure;
    this.init();
    this.filters("first_run");
  },
  methods: {
    init() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: init,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.initData = response.data.data;
        }
        self.loading = false;
      });
    },
    changePage() {
      this.filterVal.per_page = this.perPage;
      this.filters();
    },
    refresh() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: refresh,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.initData = response.data.data;
        } else {
          self.loginData = response.data.data;
          self.$store.state.loginDialog = true;
        }
        self.loading = false;
      });
    },
    incompleteCount() {
      this.loadingRefresh = true;
      let self = this;
      this.$http({
        method: "get",
        url: incompleteCount,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.initData.complete_invoices =
            response.data.data.complete_invoices;
          self.initData.incomplete_invoices =
            response.data.data.incomplete_invoices;
          self.initData.subscribers = response.data.data.subscribers;
          self.initData.jobs = response.data.data.jobs;
        }
        self.loadingRefresh = false;
      });
    },
    complete() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: complete,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.initData.jobs = response.data.jobs;
        } else {
          self.loginData = response.data.data;
          self.$store.state.loginDialog = true;
        }
        self.loading = false;
      });
    },
    filters(type) {
      if (
        (type === "clear_date" && this.dateRange.startDate == null) ||
        (this.lastPage === this.invoices.meta.current_page &&
          type === "pagination")
      )
        return;

      this.loadingTable = true;
      let self = this;
      if (type === "clear_date") {
        self.dateRange.startDate = null;
        self.dateRange.endDate = null;
        self.filterVal.from_date = null;
        self.filterVal.to_date = null;
      }

      if (self.dateRange.startDate != null) {
        self.filterVal.from_date = self.$options.filters.date(
          self.dateRange.startDate
        );
        self.filterVal.to_date = self.$options.filters.date(
          self.dateRange.endDate
        );
      }
      if (type !== "pagination" && type !== "first_run") {
        this.$store.state.selected = [];
        self.invoices.meta.current_page = 1;
      }
      if (type === "clear_search") self.filterVal.search = "";
      this.$http({
        method: "post",
        url: filter + "?page=" + self.invoices.meta.current_page,
        data: self.filterVal,
      }).then(function (response) {
        console.log(response);
        self.invoices = response.data;
        self.lastPage = response.data.meta.current_page;
        self.loadingTable = false;
      });
    },
  },
};
</script>
